import React from 'react'
import Helmet from 'react-helmet';
import { Image, Container } from 'semantic-ui-react';
import HomeLayout from '../components/HomeLayout'
import styled from 'styled-components';
import img from '../../static/img/updated-why-2.svg';
// import QuestionWrapper from '../components/Onboarding/QuestionWrapper';
// import { graphql } from 'gatsby';
// import {
//   FormiumForm, defaultComponents } from '@formium/react';
// import { formium } from '../lib/formium';
// // export function FeedbackForm({ data }) {
// //   console.log(JSON.stringify(data));

// //   return <FormiumForm data={data.formiumFormTest.formiumForm} />;
// // }
const StyledContainer = styled(Container)`
  min-height: 40vh;
  margin-bottom: 40px !important;
  @media (max-width: ${({ theme }) => theme.mobile}) {
    margin-top: 20px;
  }
`;

// const TextInput = styled((props) => (
//   <input {...props} className="my-field" />
// ))`
// font-size: 55px !important;

// .my-field {
//   font-size: 55px !important;
//   color: pink !important;
// }`

// export const query = graphql`
//     query formiumApplication { 
//     formiumForm(slug: { eq: "universal-application" }) {
//       id
//       createAt
//       name
//       projectId
//       schema
//       slug
//       updateAt
//       version
//     }
//     }
// `;
// // Always define these outside of React so that
// // components are not needlessly recreated on each render
// const myComponents = {
//   ...defaultComponents,
//   TextInput,
// }

// TODO: figure out why the 404 component i created is missing; the build fails on this file if everything is commented out
const ApplicationForm = styled(({ className, data }) => (
  <HomeLayout>
    <Helmet title="Universal Application" />
    
    <StyledContainer className={className}>
      <div className="col-left">
        <Image src={img} alt="404 Not found" />
      </div>
      <div className="col-right">
        <div className="text-h1 txt-color-3">Uh oh!</div>
        <p className="text-h3">We couldn't find that page.</p>
        <p className="text-p">You just hit a route that doesn&#39;t exist... the sadness.</p>
      </div>
    </StyledContainer>
  </HomeLayout>
))`
  h1 {
    font-weight: 900;
    font-size: 50px;
    color: #CD5B49 !important;
    text-align: center !important;
  }
  label {
    font-size: 16px;
    font-weight: 500;
    margin-right: 10px;
  }
  input {
    border-radius: 19px !important;
    padding: 8px 10px !important;
    font-size: 16px;
  font-family: Poppins !important;

  }
  div {
    margin-bottom: 20px;
  }
  form{
    width: 100%;
  }
  margin: auto;
  display: flex !important;
  flex-direction: row;
  flex-wrap: wrap;
  min-height: 60vh;
  
  button {
    font-family: Poppins !important;
    border-radius: 23px !important;
    background: #CD5B49 !important;
    margin: 0 auto !important;
    display: block !important;
    min-width: 200px;
    padding: 10px 22px !important;
    height: 46px !important;
    color: white;
    font-size: 16px;
    border: none;
  }
`;

export default ApplicationForm
